// Here you can add other styles
.btn-outline-warning {
    color: #ec971f;
    background-color: transparent;
    background-image: none;
    border-color: #ec971f;

    &:hover {
        color: #fff;
        background-color: #ec971f;
    }

    &.focus, &:focus {
        color: #fff !important;
        background-color: #ec971f !important;
    }

    &.active, &:active {
        color: #fff !important;
        background-color: #ec971f !important;
    }
}

.DateRangePicker_picker{
    z-index: 999 !important;
}


@media screen and (min-width: 1401px) {
  h3.responsive-label-text {
    font-size: 28px;
  }
}

@media screen and (max-width: 1400px) {
  h3.responsive-label-text {
    font-size: 3vw;
  }
}

@media screen and (min-width: 751px) {
  .responsive-label-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .responsive-label-text {
    font-size: 3.5vw;
  }
}
